::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--ip-gray-200);
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--ip-secondary-rgb), 0.7);
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ip-secondary);
}

.ip-time-picker {
  .ngb-tp-input-container {
    width: 5rem;
  }
}

.form-error {
  font-size: 0.875em;
  color: var(--ip-red);
}

.form-floating {
  .form-error {
    position: absolute;
  }
}

textarea.form-control {
  border-radius: var(--ip-border-radius-xxl);
  min-height: 7rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.font-anton {
  font-family: $secondary-font;
  font-weight: 400;
  font-style: normal;
}

.font-body {
  font-family: $primary-font;
  font-weight: 400;
  font-style: normal;
}

.text-transform-none {
  text-transform: none;
}

// Background colors
$bg-colors: (
  teal: var(--ip-teal),
  cyan: var(--ip-cyan),
  red: var(--ip-red),
  yellow: var(--ip-yellow),
  tertiary: var(--ip-tertiary-bg),
);

@each $key, $value in $bg-colors {
  .bg-#{inspect($key)} {
    background-color: $value;
  }
}

.bg-pattern {
  &::before {
    content: '';
    background-image: url(/assets/images/in_pattern.svg);
    background-repeat: repeat-x;
    background-size: 840px;
    position: absolute;
    left: 0;
    width: calc(100vw - 16%);
    height: 50%;
    z-index: -2;
    top: 50%;
    @media (min-width: 992px) {
      top: 0;
      width: calc(50vw - 4px);
      height: 100%;
      left: 50%;
    }
  }
}
.bg-green-rounded {
  background-color: var(--ip-secondary);
  position: absolute;
  left: 50%;
  width: calc(100vw - 4px);
  max-width: none;
  transform: translateX(-50vw);
  height: 50%;
  top: 0;
  z-index: -1;
  @media (min-width: 992px) {
    border-radius: 0 50rem 50rem 0;
    top: 2rem;
    width: 70vw;
    height: calc(100% - 2rem);
  }
}
.bg-rounded {
  border-radius: 10rem;
}
.how-to-section {
  position: relative;
  padding-bottom: 8rem;
  @media (max-width: 991.98px) {
    padding-bottom: 3rem;
    .bg-secondary {
      background: none !important;
      h3 {
        color: var(--ip-secondary) !important;
      }
    }
  }
  &.bg-pattern::before {
    content: none;
    @media (min-width: 992px) {
      content: '';
      height: 50%;
      width: 100vw;
      top: 50%;
      left: 0;
      background-repeat: repeat;
    }
  }
}
.bg-in {
  background-image: url('/assets/images/i_ip_originale.svg');
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 992px) {
    background-image: url('/assets/images/i_ip.svg');
    background-position: left bottom;
  }
}
.bg-dualtone {
  position: relative;
  &::before {
    content: '';
    background-color: var(--ip-secondary);
    background-image: url('/assets/images/in_pattern.svg');
    background-repeat: repeat;
    background-size: 840px;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    top: 0;
    border-radius: 2rem;
    background-blend-mode: hard-light;
    @media (min-width: 992px) {
      border-radius: 10rem;
      height: 230%;
    }
  }
  &.dualtone-white {
    &::before {
      background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
        url('/assets/images/in_pattern.svg');
      background-color: white;
      background-blend-mode: unset;
    }
  }
}
.bg-center {
  background-position: left center;
  background-image: url('/assets/images/i_ip_originale.svg');
}
.bg-halftop {
  @media (min-width: 992px) {
    &::after {
      content: '';
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50vw);
      z-index: -2;
      width: 25vw;
      height: 200%;
      background-color: var(--ip-secondary);
      border-radius: 0 50rem 0 0;
    }
  }
}
.bg-halfbottom {
  &::before {
    content: '';
    position: absolute;
    z-index: -3;
    @media (max-width: 991.98px) {
      height: 200%;
      width: 105vw;
      bottom: -160px;
      left: 40%;
      transform: translateX(-50vw);
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('/assets/images/i_ip_originale.svg');
    }
    @media (min-width: 992px) {
      background-color: #01a48d;
      border-radius: 29% 25% 68% 32%/0 0 100% 30%;
      height: 180%;
      width: calc(100vw - 4px);
      bottom: 0;
      left: 50%;
      transform: translateX(-50vw);
    }
  }
  &.halfbottom-candidato {
    &::before {
      @media (min-width: 992px) {
        bottom: auto;
        top: 0;
      }
    }
  }
}
.bg-halfbottom-reverse::before {
  content: '';
  position: absolute;
  z-index: -3;
  background-color: #01a48d;
  left: 50%;
  width: calc(100vw - 4px);
  bottom: -5rem;
  transform: translateX(-50vw);
  @media (min-width: 992px) {
    border-radius: 0 70% 0% 0;
    height: 100%;
  }
  @media (max-width: 991.98px) {
    border-radius: 0 70% 0% 0;
    height: 50%;
  }
}
.bg-middle {
  &::before {
    @media (min-width: 992px) {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: #01a48d;
      height: 500px;
      width: 100vw;
      bottom: 50%;
      left: 0;
    }
  }
  &::after {
    @media (min-width: 992px) {
      content: '';
      position: absolute;
      background-color: var(--ip-body-bg);
      right: 0;
      bottom: 50%;
      height: 510px;
      width: 75vw;
      z-index: -1;
      border-radius: 50%/100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.sticky-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.sticky-search {
  padding-bottom: 1rem;
  margin-top: auto;
  width: 100%;
  &::before {
    content: '';
    background-color: white;
    height: calc(100% + 1rem);
    width: calc(100vw - 7px);
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3rem 3rem 0 0;
  }
}
.flat-search-mobile {
  transform: translateY(-100px);
  .hide {
    display: none;
  }
}

.w-icon {
  width: 16px;
  height: 16px;
}

a:has(i) {
  text-decoration: none;
}

.link-underline-light {
  text-decoration: underline;
}

.navbar-nav {
  text-transform: uppercase;
}

.avatar {
  width: 24px;
  height: 24px;
}

.text-underline {
  text-decoration: underline;
}

.btn {
  text-transform: uppercase;
  @media screen and (min-width: 991.98px) {
    padding: 0.4rem 3rem;
  }
}

.btn-primary {
  --ip-btn-color: #fff;
  --ip-btn-hover-color: #fff;
}

.btn-outline-primary {
  --ip-btn-hover-color: #fff;
  &.text-body-secondary:hover {
    color: var(--ip-btn-hover-color) !important;
  }
}

.grouped-negative {
  .form-control {
    margin-right: -1rem;
  }

  .btn {
    z-index: 6;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
  }
}

label {
  &.btn-primary {
    --ip-btn-color: #fff;
    --ip-btn-hover-color: #fff;
    --ip-btn-bg: #99d2c8;
    --ip-btn-border-color: #99d2c8;
    &.bg-tr {
      --ip-btn-bg: transparent;
    }
    &.active {
      --ip-btn-active-color: #fff;
      --ip-btn-active-bg: #02a48d;
    }
  }
}

.btn-check:checked + label.btn-primary {
  --ip-btn-active-color: #fff;
}

.form-select.bg-primary {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>');
}

.rounded-50 {
  border-radius: 50rem;
}
.rounded-top-10 {
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
}
.rounded-10 {
  border-radius: 10rem;
}
.rounded-5r {
  border-radius: 5rem;
}

// modals
// .filters-modal {
//   height: 100%;
//   margin: 0;

//   .modal-content {
//     height: 100%;
//     background-color: rgba(var(--ip-white-rgb), 1);
//     border-radius: 0;

//     .modal-header {
//       justify-content: space-between;
//     }
//   }
// }

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
  .border-lg-bottom {
    border-bottom: var(--ip-border-width) var(--ip-border-style) var(--ip-border-color);
  }
  .rounded-top-lg-5 {
    border-top-right-radius: var(--ip-border-radius-xxl) !important;
    border-top-left-radius: var(--ip-border-radius-xxl) !important;
  }
  .dtop-xpander {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .dtop-rounded-white {
    border-radius: var(--ip-border-radius-xxl) !important;
    background-color: white;
    input[type='text'],
    select {
      background-color: white;
    }
    .results-type {
      background-color: rgba(var(--ip-primary-rgb), 0.5) !important;
    }
  }
  .border-lg-end {
    border-right: var(--ip-border-width) var(--ip-border-style) var(--ip-border-color) !important;
  }
  .max-width-md {
    max-width: 992px;
  }
  .text-lg-white {
    color: white;
  }
  .bg-lg-secondary {
    background-color: var(--ip-secondary);
  }
}

@media (max-width: 991.9px) {
  .border-mobile-none {
    border-width: 0;
  }
  .edit-section {
    .btn-outline-primary,
    .btn-outline-secondary {
      span {
        display: none;
      }
    }
  }
  .duotone {
    background-color: var(--ip-secondary);
  }

  .img-to-circle {
    padding-top: calc(100% + 3rem);
    position: relative;
    margin-left: -3rem;
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: calc(100% + 3rem);
      border-radius: 50%;
      max-width: unset;
    }
  }
  .we-are-inplace {
    .px-15 {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    .g-0 {
      background-color: white;
      border-radius: 50rem 50rem 9rem 9rem;
    }
  }
  .rounded-until-lg-5 {
    border-radius: 2rem;
  }
  .text-until-lg-secondary {
    color: var(--ip-secondary);
  }
  .app-download {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    > .row {
      margin-left: 0;
      margin-right: 0;
      background: white;
      padding-bottom: 2rem;
      border-radius: 50rem 50rem 10rem 10rem;
      .img-to-circle {
        margin-left: 0;
        padding-top: 100%;
        img {
          width: 100%;
        }
      }
    }
  }

  .bg-mobile-in::before {
    content: '';
    position: absolute;
    z-index: -3;
    height: 200%;
    width: 105vw;
    top: 160px;
    left: 40%;
    transform: translateX(-50vw);
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/assets/images/i_ip_originale.svg);
  }
}

.container-pill {
  @media (min-width: 992px) {
    padding-left: 6rem;
    background-color: white;
    &.pill-pe {
      padding-left: 0;
      padding-right: 6rem;
    }
  }
  @media (max-width: 991.98px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    > .row {
      background-color: #fff;
      border-radius: 50rem 50rem 9rem 9rem;
      margin: 0;
      .col-12:nth-child(2) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge-alert {
  right: -0.3rem;
  top: -0.3rem;
  font-size: 0.675rem;
}

.badge-alert-label {
  right: -1.6rem;
  top: -0.7rem;
  font-size: 0.675rem;
  padding: 0.5rem;
}

.toast {
  .btn-close {
    --ip-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006b6c' %3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  }
}

/**
Offcanvas
*/
.aside-nav-menu {
  &.show,
  &.showing,
  &.hiding {
    padding-left: 0;
    padding-right: 0;
    .btn-close {
      right: 1.5rem;
    }
  }

  background-color: var(--ip-white);

  .offcanvas-header {
    background-color: var(--ip-secondary);
    border-bottom-left-radius: var(--ip-border-radius-xxl);
    border-bottom-right-radius: var(--ip-border-radius-xxl);
    color: var(--ip-white);
    align-items: unset;
  }

  h3 {
    color: var(--ip-white);
  }

  .offcanvas-body {
    background-color: var(--ip-white);
    .nav {
      --ip-link-hover-color: rgba(var(--#{$prefix}black-rgb), 0.8);

      hr {
        width: 100%;
        border-color: var(--ip-primary);
      }

      .nav-item {
        width: 100%;
        .nav-link {
          display: flex;
          padding-left: 0;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23006b6c" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg>');
          background-repeat: no-repeat;
          background-position: right 10px center;
          i {
            margin-right: 0.3rem;
          }
          &.active {
            color: var(--ip-secondary);
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .aside-nav-menu {
    color: var(--ip-secondary);
    background-color: var(--ip-transparent);

    .offcanvas-header {
      background-color: var(--ip-white);
      border-top-left-radius: var(--ip-border-radius-xxl);
      border-top-right-radius: var(--ip-border-radius-xxl);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      color: var(--ip-secondary);
    }

    .offcanvas-body {
      border-radius: 0 0 2rem 2rem;
    }

    h3 {
      color: var(--ip-primary);
    }
  }
}

/**
Profile User
*/
.profile-user {
  img {
    max-width: 80%;
  }
  ul li a {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 991.98px) {
    img {
      max-width: 120%;
    }
    h1 {
      font-size: calc(1.3rem + 0.6vw) !important;
    }
  }
}

.accordion small {
  color: var(--ip-secondary-color);
}

.accordion-item {
  border-radius: var(--ip-accordion-inner-border-radius);
  overflow: hidden;
}

.accordion-button {
  text-transform: uppercase;
  font-family: $secondary-font;
}

.accordion-faq {
  .accordion-button {
    text-transform: none;
    font-family: var(--ip-font-sans-serif);
    --ip-accordion-btn-color: var(--ip-primary);
    &::after {
      --ip-accordion-btn-icon-width: 2.5rem;
      --ip-accordion-btn-icon-height: 2.5rem;
    }
  }
}

.nav-pills-faq {
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  section .toast {
    max-width: 48%;
  }
}

/**
Search page
*/
.input-group input:focus::placeholder {
  color: rgb(180, 180, 180);
}

.search-icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-group {
  .form-control,
  .form-select,
  .btn {
    border-color: var(--ip-primary);
  }
}

// .form-select {
//   --ip-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" fill="%23006b6c" viewBox="0 0 16 16"%3e%3cpath d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/%3e%3c/svg%3e');
// }
.section-filters {
  select {
    border-color: var(--ip-primary);
    padding-right: 1.5rem;
    background-position: right 0.5rem center;
  }
}

input[type='radio'].toggle-result {
  display: none;

  & + label {
    cursor: pointer;
    min-width: 50%;
    position: relative;
    color: var(--ip-primary);
    &:hover {
      background: transparent;
      color: var(--ip-primary);
    }
    &:after {
      background: var(--ip-primary);
      content: '';
      height: calc(100% - 0.7rem);
      position: absolute;
      top: 0.35rem;
      left: 0.25rem;
      transition: transform 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: calc(100% - 0.5rem);
      z-index: -1;
      border-radius: 5rem;
    }
    &:active {
      background-color: transparent;
    }
    @media (min-width: 992px) {
      border: none;
      padding: 0.8rem;
    }
  }
  &.toggle-left + label {
    border-right: 0;
    border-radius: 5rem 0 0 5rem;
    &:after {
      transform: translateX(calc(100% + 0.25rem));
    }
  }
  &.toggle-right + label {
    margin-left: -5px;
    border-left: 0;
    border-radius: 0 5rem 5rem 0;
    &:after {
      transform: translateX(calc((100% - 0.35rem) * -1));
    }
  }
  &:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms;
    &:after {
      transform: translateX(0);
    }
  }
}

.articolo-litsa-candidati {
  .btn-outline-dark:hover {
    color: var(--ip-primary);
  }
}

.pagination .page-link {
  border-radius: 50% !important;
  min-width: 2.2rem;
  text-align: center;
  margin-right: 0.3rem;
  line-height: 1.4;
}

/**
Drop Area
*/
#drop-area {
  border: 2px dashed var(--ip-tertiary-bg);
  border-radius: 20px;
  width: 100%;
  max-width: 28rem;
  margin: 3rem auto;
  height: 14rem;
  &.highlight {
    border-color: var(--ip-primary);
  }
  .drag-form {
    position: relative;
    width: 100%;
    height: 100%;
    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .btn-outline-primary {
        color: #57595a;
        border-color: #57595a;
        width: 1.8rem;
        height: 1.8rem;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  #fileElem {
    display: none;
  }
}

/**
Footer
*/
footer {
  h5 {
    color: white;
    font-family: var(--ip-font-sans-serif);
    text-transform: none;
  }
  .nav-link {
    color: white;
    &:hover {
      color: white;
    }
  }
}

@media (max-width: 991.9px) {
  /**
Form
*/
  form {
    button[type='submit'] {
      width: 100%;
    }
  }
  /**
Accordion
*/
  #footer {
    .col-lg {
      > h5 {
        margin-bottom: 0 !important;
        padding-bottom: 1.2rem;
        padding-top: 1.2rem;
        position: relative;

        &:before {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/></svg>');
          content: '';
          right: 16px;
          height: 14px;
          width: 14px;
          transform: rotate(0deg);
          transition: transform 0.3s ease-in-out;
          position: absolute;
          background-repeat: no-repeat;
        }
      }
      .nav {
        transition:
          max-height 0.2s ease-in-out,
          opacity 0.2s ease-in-out;
        opacity: 0;
        height: auto;
        max-height: 0;
        overflow: hidden;
      }
      &.active {
        > h5:before {
          transform: rotate(180deg);
        }

        > .nav {
          max-height: 500px;
          opacity: 1;
        }
      }
    }
  }
}

/**
Date Picker
*/
.air-datepicker {
  --adp-cell-background-color-selected: var(--ip-primary);
  --adp-day-name-color: var(--ip-primary);
  --adp-cell-background-color-selected-hover: var(--ip-primary);
}
.air-datepicker-cell.-range-from-,
.air-datepicker-cell.-range-to- {
  border-radius: 5rem;
}
.air-datepicker-cell.-current- {
  --adp-color-current-date: var(--ip-primary);
  border-radius: 5rem;
  border: solid 1px var(--ip-primary);
}
.air-datepicker-global-container {
  z-index: 2050;
}
.air-datepicker-cell.-in-range- {
  --adp-cell-background-color-in-range: rgba(var(--ip-secondary-rgb), 0.5);
}
.air-datepicker.-bottom-left-.-from-bottom-.-is-mobile-.-active- {
  padding: 4%;
  border-radius: 20px;
  width: 60%;
  min-width: 320px;
}
.air-datepicker-cell.-range-from-,
.air-datepicker-cell.-range-to- {
  border-radius: 0;
  color: #fff;
  border: 0;
}
.air-datepicker-cell.-range-from- {
  background: linear-gradient(90deg, #fff 50%, rgba(var(--ip-secondary-rgb), 0.5) 50%);
}
.air-datepicker-cell.-range-to- {
  background: linear-gradient(90deg, rgba(var(--ip-secondary-rgb), 0.5) 50%, #fff 50%);
}
.air-datepicker-cell.-range-from-:before,
.air-datepicker-cell.-range-to-:before {
  content: ' ';
  position: absolute;
  background-color: var(--adp-cell-background-color-selected);
  height: 38px;
  width: 38px;
  border-radius: 19px;
  z-index: -1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-arrow {
  cursor: default;
}

.display-inline {
  display: inline;
}

.ngb-dp-day.disabled {
  color: lightgray;
}
