/**
Swiper
*/
.swiper {
  &.swiper-pills {
    overflow: visible;

    .swiper-slide {
      background-color: #fff;
      border-radius: 15rem;
      text-align: center;
      padding: 4rem 3rem;
      height: auto;

      > div {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: 991.98px) {
        height: 100%;
        &:nth-child(even) {
          margin-top: 4rem;
        }
      }
    }
  }

  &.swiper-pills-ext {
    overflow: visible;

    .swiper-slide {
      background-color: #fff;
      border-radius: 15rem;
      text-align: center;
      padding: 4rem 3rem;
      min-height: 50vh;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;

      @media (min-width: 768px) {
        min-height: 60vh;
      }

      > div {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(even) {
        margin-top: 4rem;
      }
    }
  }
}
