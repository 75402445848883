$primary-font: 'DM Sans', sans-serif;
$secondary-font: 'Anton', sans-serif;

$enable-reduced-motion: false;

$prefix: ip-;
$font-family-sans-serif:
  inspect($primary-font),
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$primary: #006b6c;
$secondary: #01a48d;
$light: #eff2f6;
$teal: #8dbd41;
$cyan: #ced7e4;
$yellow: #fbf198;
$red: #fb9898;
$body-color: #000000;
$body-bg: $light;
$body-tertiary-color: #adb5bd;
$body-tertiary-bg: #adb5bd;
$headings-font-family: $secondary-font;
$headings-color: #02a48d;
$border-radius: 50rem !default;
$border-radius-sm: 50rem !default;
$border-radius-lg: 50rem !default;
$border-color: #000000;
$dropdown-border-radius: var(--#{$prefix}border-radius-xl);
$input-btn-padding-x: 1.25rem !default;
$input-btn-padding-x-sm: 2rem !default;
$input-border-color: $primary;
$input-group-addon-color: $primary;
$input-group-addon-bg: transparent;
$btn-font-family: $secondary-font;
$btn-padding-y: 0.7rem !default;
$form-label-font-size: 0.875rem !default;
$form-check-input-border: 1px solid $primary !default;
$form-select-indicator-color: $primary;
$form-select-indicator: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{$form-select-indicator-color}" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>');
$navbar-light-color: $primary;
$navbar-light-icon-color: #ffffff;
$navbar-toggler-focus-width: 0;
$navbar-toggler-border-radius: 5rem;
$badge-padding-x: 1rem;
$accordion-bg: white;
$accordion-button-color: $secondary;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $secondary;
$accordion-button-focus-box-shadow: none;
$accordion-border-radius: 1rem;
$accordion-border-width: 0;
$accordion-button-icon: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20" fill="%23016C6D"><path d="M10,0.1c-5.4,0-9.9,4.4-9.9,9.9c0,5.4,4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9C19.9,4.6,15.4,0.1,10,0.1z M13.5,8.7l-3.3,3.1c-0.1,0.1-0.3,0.1-0.4,0L6.5,8.7c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0l3.1,2.9l3.1-2.9c0.1-0.1,0.3-0.1,0.4,0C13.6,8.4,13.6,8.6,13.5,8.7z"/></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20" fill="%23016C6D"><path d="M10,0.1c-5.4,0-9.9,4.4-9.9,9.9c0,5.4,4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9C19.9,4.6,15.4,0.1,10,0.1z M13.5,8.7l-3.3,3.1c-0.1,0.1-0.3,0.1-0.4,0L6.5,8.7c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0l3.1,2.9l3.1-2.9c0.1-0.1,0.3-0.1,0.4,0C13.6,8.4,13.6,8.6,13.5,8.7z"/></svg>');
$modal-content-border-radius: 1.5rem;
$form-select-padding-x-sm: 0.8rem !default;
$pagination-bg: transparent;
$pagination-color: $primary;
$pagination-border-color: transparent;
$pagination-hover-color: $primary;
$pagination-hover-bg: rgba(var(--#{$prefix}primary-rgb), 0.2);
$pagination-hover-border-color: transparent;
$pagination-active-bg: $primary;
$pagination-active-color: #ffffff;
$pagination-disabled-color: rgba(var(--#{$prefix}primary-rgb), 0.7);
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;
$toast-background-color: $light;
$toast-border-width: 0;
$toast-border-radius: 0.5rem;
$toast-box-shadow: none;
$toast-header-background-color: transparent;

$link-color: $primary;
// $link-hover-color: rgba(var(--#{$prefix}black-rgb), 0.8);
