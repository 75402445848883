@font-face {
  font-family: 'in-place';
  src:
    url('/assets/fonts/in-place.ttf?l4wulo') format('truetype'),
    url('/assets/fonts/in-place.woff?l4wulo') format('woff'),
    url('/assets/fonts/in-place.svg?l4wulo#in-place') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'in-place' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star-fill:before {
  content: '\e91f';
}
.icon-star:before {
  content: '\e920';
}
.icon-meet-video:before {
  content: '\e91a';
}
.icon-accordion:before {
  content: '\e91b';
}
.icon-chat-box:before {
  content: '\e91c';
}
.icon-check:before {
  content: '\e91d';
}
.icon-contract:before {
  content: '\e91e';
}
.icon-dashboard:before {
  content: '\e900';
}
.icon-edit-profile:before {
  content: '\e901';
}
.icon-in-place .path1:before {
  content: '\e902';
  color: rgb(3, 3, 5);
}
.icon-in-place .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(3, 3, 5);
}
.icon-in-place .path3:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(1, 164, 142);
}
.icon-in-place .path4:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(1, 164, 142);
}
.icon-add:before {
  content: '\e906';
}
.icon-edit:before {
  content: '\e907';
}
.icon-search:before {
  content: '\e908';
}
.icon-warning:before {
  content: '\e909';
}
.icon-alert:before {
  content: '\e90a';
}
.icon-bookmark:before {
  content: '\e90b';
}
.icon-chat:before {
  content: '\e90c';
}
.icon-cog:before {
  content: '\e90d';
}
.icon-contacts:before {
  content: '\e90e';
}
.icon-doc:before {
  content: '\e90f';
}
.icon-doc-edit:before {
  content: '\e910';
}
.icon-find:before {
  content: '\e911';
}
.icon-interview:before {
  content: '\e912';
}
.icon-clock:before {
  content: '\e913';
}
.icon-calendar:before {
  content: '\e914';
}
.icon-select-arrow:before {
  content: '\e915';
}
.icon-download:before {
  content: '\e916';
}
.icon-filters:before {
  content: '\e917';
}
.icon-pin:before {
  content: '\e918';
}
.icon-log-out:before {
  content: '\e919';
}
