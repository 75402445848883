$placeholder-color: $gray-200;

// Custom styles for ng-select using Bootstrap variables
.ng-select {
  width: 100%; // Full width for the select component

  &.ng-select-opened {
    &.ng-select-bottom {
      .ng-select-container {
        @include border-radius($form-select-border-radius, 0);
      }
    }

    .ng-select-container {
      background-color: var(--ip-body-bg);
      border: $form-select-border-width solid $form-select-border-color;
      @include border-radius($form-select-border-radius, 0);
    }

    .ng-arrow-wrapper,
    .ng-arrow-wrapper:hover {
      .ng-arrow {
        border-color: transparent transparent $primary;
      }
    }
  }

  // Override input padding, colors, and font size
  .ng-select-container {
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    appearance: none;
    background-color: $form-select-bg;
    background-image: var(--#{$prefix}form-select-bg-img), var(--#{$prefix}form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: $form-select-bg-position;
    background-size: $form-select-bg-size;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);

    &:hover {
      border-color: $primary;
    }

    &.ng-select-focused {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }
  }

  // Customizing placeholder text color
  .ng-placeholder {
    color: $placeholder-color;
  }

  // Customize dropdown panel
  .ng-dropdown-panel {
    border-radius: $border-radius;
    border-color: $border-color;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);

    .ng-dropdown-panel-items {
      max-height: 250px; // Max height for scrolling
      overflow-y: auto;

      // Style each dropdown item
      .ng-option {
        background-color: $form-select-bg;
        padding: $input-padding-y $input-padding-x;
        &:hover,
        &.ng-option-selected,
        &.ng-option-marked {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  // Style selected items in multi-select
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          background-color: $secondary;
          color: $white;
          border-radius: $badge-border-radius;
          padding: 0.25em 0.5em;
          margin-right: 0.25rem;

          .ng-value-icon {
            color: $white;
            &:hover {
              background-color: $secondary;
            }
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $primary;
  }

  .ng-arrow-wrapper,
  .ng-arrow-wrapper:hover {
    .ng-arrow {
      border-color: $primary transparent transparent;
    }
  }
}
